import React, { useState } from "react"
import { Container } from "react-bootstrap"
import "./CareerDetailsComponent.scss"
import parse from "html-react-parser"
import { graphql, useStaticQuery } from "gatsby"
import StaticContactCard from "../StaticContactCard/StaticContactCard"
import useDeviceMedia from "../../hooks/useDeviceMedia"
import SocialShareComponent from "../SocialShareComponent/SocialShareComponent"
import { Modal } from "react-bootstrap"
import DetailStaticForm from "../forms/DetailStaticForm"

const CareerDetailsComponent = ({ teamData }) => {
  const [shareIcons, setShareIcons] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const data = useStaticQuery(graphql`
    query getGlobalData {
      glstrapi {
        globalModule {
          ...globalModuleData
        }
      }
    }
  `)

  const modalHandler = () => {
    setShowModal(true)
  }
  const closeModal = () => {
    setShowModal(false)
  }
  const { category, description, salary, title, team } = teamData
  const aboutAllegiance = data.glstrapi.globalModule?.About_Allegiance
  const staticData = {
    title: "Interested in this job?",
    desc: `With more than 50 international roadshows and over 10 billion of Sales, Allegiance is ranked as the UAE's #1 Off-Plan Real Estate Advisory. Be a part of an award winning team, Send us your CV today!`,
  }
  const shareurl = typeof window !== "undefined" ? window.location.href : ""

  const openShareicons = () => {
    setShareIcons(true)
    if (shareIcons === true) {
      setShareIcons(false)
    }
  }

  const clickHandler=()=>{
    const getId=document.getElementById("contact-block");
    getId.scrollIntoView({behavior:"smooth"})
  }

  const { isLargeScreen, isMobile } = useDeviceMedia()
  return (
    <>
      <div className="career-details-wrapper">
        <Container>
          <>
            <div className="career-details-top-content">
              <h1>{title}</h1>
              <div className="career-details-info">
                {isMobile ? (
                  <>
                    <div className="carrer_detail">{category}</div>
                    <div className="vertical-line"></div>
                    <div
                      className="share-career-detail mobile"
                      onClick={() => openShareicons()}
                    >
                      <i className="icon black-share-icon" />
                      <span className="carrer_detail">Share</span>
                      {shareIcons && (
                        <SocialShareComponent
                          openShareicons={openShareicons}
                          shareurl={shareurl}
                        />
                      )}
                    </div>

                    <div className="carrer_detail mobile">{salary}</div>
                  </>
                ) : (
                  <>
                    <div className="carrer_detail">{category}</div>
                    <div className="vertical-line"></div>
                    {salary&&
                    <>
                    <div className="carrer_detail">{salary}</div>
                    <div className="vertical-line"></div>
                    </>
                    }
                    <div
                      className="share-career-detail"
                      onClick={() => openShareicons()}
                    >
                      <i className="icon black-share-icon" />
                      <span className="carrer_detail">Share</span>

                      {shareIcons && (
                        <SocialShareComponent
                          openShareicons={openShareicons}
                          shareurl={shareurl}
                        />
                      )}
                    </div>
                  </>
                )}
              </div>

              {isLargeScreen && (
                <div className="carrer-details-ctas">
                  <a
                    to="#"
                    className="button button-black"
                    onClick={() => clickHandler()}
                  >
                    <i className="icon white-envelope" />
                    <span>Apply</span>
                  </a>
                  <a
                    href={"tel:" + team?.mobile_no}
                    className="button button-black"
                  >
                    <i className="icon white-phone" />
                    <span>Call</span>
                  </a>
                </div>
              )}
            </div>
            <div className="career-details-section">
              <div className="career-details-left">
                <>
                  {parse(description)}
                  {parse(aboutAllegiance)}
                </>
              </div>
              <div className="career-details-right">
                <StaticContactCard content={staticData} team={team} />
              </div>
            </div>
          </>
        </Container>
        <Modal
          show={showModal}
          onHide={() => setShowModal(false)}
          aria-labelledby="contained-modal-title-vcenter"
          centered
          className="more-filters-modal stay-updated team-listing-modal"
          id="filter-modal"
          // fullscreen={"xl-down"}
        >
          <button
            className="stay-button-form team-listing-form"
            type="button"
            onClick={() => closeModal()}
          >
            <i className="icon cancel-icon" />
          </button>
          <Modal.Body>
            <div className="modal-body-container">
              <DetailStaticForm />
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </>
  )
}

export default CareerDetailsComponent
