import { graphql } from "gatsby"
import React from "react"
import CareerDetailsComponent from "../components/CareerDetailsComponent/CareerDetailsComponent"
import GlobalFormComponent from "../components/GlobalFormComponent/GlobalFormComponent"
import Footer from "../components/Footer/Footer"
import Header from "../components/Header/Header"
import { aboutURL, careerURL } from "../site/urls"
import Breadcrumbs from "../components/Breadcrumbs/Breadcrumbs"
import SEO from "../components/Seo/seo"
import useCompanyInfo from "../hooks/useCompanyInfo"
import useDeviceMedia from "../hooks/useDeviceMedia"
import { getWhatsAppURL } from "../components/common/site/utils"
import balckPhoneImg from "../images/black-phone.svg"
import whatsappImg from "../images/whatsapp.svg"
import MobileContact from "../components/MobileContact/MobileContact"

const CareerDetailsTemplate = ({ data }) => {
  const { phone } = useCompanyInfo()
  const {isLargeScreen,isMobile}=useDeviceMedia()
  const teamData = data.glstrapi.career
  const formStaticData = {
    title: "apply for this job",
    description:
      "Maecenas mollis arcu vitae hendrerit interdum nulla facilisi quisque placerat dolor.",
  }

  const staticURLs = [
    { url: aboutURL, label: "About" },
    { url: careerURL, label: "Careers" },
    { url: "", label: teamData.title },
  ]

  return (
    <div className="career-detail-page">
      <SEO title={teamData.title} description={teamData.description} />
      <Header />
      {!isLargeScreen&&
       <div className="social-section">
          <a href={getWhatsAppURL(phone)} target="_blank">
            <img
              src={whatsappImg}
              alt="social-whatsapp"
              className="social-icon social-whatsapp"
            />
          </a>
          <a href={"tel:" + phone}>
            <img
              src={balckPhoneImg}
              alt="social-phone"
              className="social-icon social-phone"
            />
          </a>
        </div>
        }
         {
          isMobile&&
          <MobileContact/>
        }
      <Breadcrumbs staticURLs={staticURLs} />
      <CareerDetailsComponent teamData={teamData} />
      <GlobalFormComponent module={formStaticData} formName="career" />
      <Footer categoryType="category1"/>
    </div>
  )
}

export default CareerDetailsTemplate

export const pageQuery = graphql`
  query GetCarrerPage($articleId: ID!) {
    glstrapi {
      career(id: $articleId) {
        description
        category
        id
        salary
        slug
        title
        team {
          ...teamData
        }
      }
    }
  }
`
